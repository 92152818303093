<template>
    <div class="page">

        <div class="page__header">
            <div class="page__info">
                <div class="page__title">Добавление товара</div>
                <div class="page__desc">Добавление товара к заказу</div>
            </div>
        </div>

        <div class="page__content" v-if="order">
            <div class="row">
                <div class="col-sm-6">
                    <h2>Товар</h2>
                    <form class="form-group" method="POST" action="" @submit.prevent="addProduct()">
                        <label for="product_id">Продукт</label>
                        <div v-if="products" class="control-group">
                            <select
                                    v-model="product_id"
                                    id="product_id"
                                    class="form-control"
                                    required>

                                <template
                                        v-for="cc in products"
                                        :label="cc['title']">

                                    <optgroup
                                            v-bind:key="cc['id']"
                                            :label="cc['title']"></optgroup>

                                    <optgroup
                                            v-for="c in cc['categories']"
                                            v-bind:key="c['id']"
                                            :label="'↳' + c['title']">

                                        <option
                                                v-for="p in c['products']"
                                                v-bind:key="p['id']"
                                                :value="p['id']">{{ p['title'] }}</option>
                                    </optgroup>
                                </template>
                            </select>
                        </div>
                        <br>

                        <label for="quantity">Количество</label>
                        <div class="control-group">
                            <input
                                    v-model="quantity"
                                    id="quantity"
                                    type="number"
                                    class="form-control"
                                    required>
                        </div>
                        <br>

                        <input type="submit" class="btn btn-success btn-large" value="Добавить товар">
                    </form>
                </div>
                <div class="col-sm-6">
                    <h2>Потребител</h2>
                    <template v-if="order.user != null">
                        <b>ID: </b> {{ order.user.id }}<br>
                        <b>Име: </b> {{ order.user.name }}<br>
                        <b>Телефон: </b> {{ order.user.phone }}<br>
                        <b>Email: </b> {{ order.user.email }}<br>
                    </template>
                    <template v-else>
                        <b>DEVICE ID: </b> {{ order.device_id }}<br>
                    </template>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'DeliveryOrdersAddProduct',
        data() {
            return {
                product_id: null,
                quantity: null
            }
        },
        computed: {
            order() {
                return this.$store.state.orders.order
            },
            products() {
                return this.$store.state.promotions.promotionsProducts
            }
        },
        methods: {
            ...mapActions([
                'deliveryOrdersGet',
                'deliveryOrdersProducts',
                'catalogPromotionsProducts',
            ]),
            async getOrder() {
                await this.deliveryOrdersGet({
                    id: this.$route.params.id,
                })
            },
            async getProducts() {
                await this.catalogPromotionsProducts()
            },
            async addProduct() {
                let data = [];
                data.push({
                    id: this.product_id,
                    quantity: this.quantity
                });

                await this.deliveryOrdersProducts({
                    id: this.order.id,
                    products: data
                })
            }
        },
        created() {
            this.getOrder();
            this.getProducts();
        }
    }
</script>